const initCollapsible = () => {
  const elements = document.getElementsByClassName("collapsible");
  for (const element of elements) {
    element.addEventListener("click", function () {
      // @ts-ignore
      this.classList.toggle("active");
      // @ts-ignore
      const content = this.nextElementSibling;
      if (content.style.maxHeight) {
        content.style.maxHeight = null;
      } else {
        content.style.maxHeight = content.scrollHeight + "px";
      }
    });
  }
};

if (window.history) {
  document.addEventListener("DOMContentLoaded", initCollapsible);
}
